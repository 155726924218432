import { useContext, useRef } from "react";
import i18next from "i18next";
import apiService from "~/services/HttpService";
import { AdvancedSearchContext } from "~/providers/AdvancedSearchProvider";
import {
    DocumentFilter,
    TextHighlights,
    SearchResultTextItem,
    SolRResponseData,
} from "~/features/Search/Results/types";
import useAnalytics from "./useAnalytics";
import { convertFiltersForAnalytics } from "~/utils/filterUtils";

const API_PREFIX = "/api/search/solr";
const API_LOG = "/api/search/insertlog";

const useSolrSearch = (type: string, rows: number = 40) => {
    const { language } = i18next;
    const culture = language === "en" ? "en-US" : "fr-FR";
    const cursorMark = useRef("*");
    const { filters } = useContext(AdvancedSearchContext);
    const { sp_click, sp_track_params } = useAnalytics();

    const {
        keyword,
        disclosureFilters,
        yearFilters,
        companyFilters,
        industryFilters,
        imageThemeFilters,
        pageSectionFilters,
        searchTerm,
        sortBy,
    } = useContext(AdvancedSearchContext);

    const payload: DocumentFilter = {
        DocumentTypeID: disclosureFilters.map((disclosure) => Number(disclosure.id)),
        SelectedBookmarkMetadata: pageSectionFilters.map((page) => Number(page.id)),
        SelectedMetadata: imageThemeFilters.map((img) => Number(img.id)),
        Companies: companyFilters.map((company) => Number(company.id)),
        DocumentYear: yearFilters.map((year) => Number(year.id)),
        Industries: industryFilters.map((industry) => Number(industry.id)),
        LanguageCode: culture,
        Keywords: keyword,
        SearchTerm: searchTerm,
        RowsPerPage: rows,
        SortBy: sortBy,
        CursorMark: cursorMark.current,
    };

    const fetchSearchResults = async (reset: boolean) => {
        if (reset) {
            payload.CursorMark = "*";
        }

        return new Promise((resolve, reject) => {
            try {
                apiService
                    .post(`${API_PREFIX}-${type}`, payload)
                    .then((response) => {
                        let items: any[] = [];
                        let total = 0;

                        if (response.status === 200) {
                            const data: SolRResponseData = response.data;
                            items = data.response.docs;
                            total = data.response.numFound;

                            if (cursorMark.current !== data.nextCursorMark) {
                                cursorMark.current = data.nextCursorMark;
                            } else {
                                cursorMark.current = "*";
                            }

                            if (data.highlighting && type === "textsearch") {
                                const highlights = Object.entries(data.highlighting).reduce((acc, [key, value]) => {
                                    acc[key] = value.Content[0] || "";
                                    return acc;
                                }, {} as TextHighlights);

                                items.forEach((item: SearchResultTextItem) => {
                                    item.ContentHighlight = highlights[item.id] ? highlights[item.id] : "";
                                });
                            }
                        }

                        resolve({
                            items,
                            total,
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(null);
                    });
            } catch (error) {
                console.error(error);
                reject(null);
            }
        });
    };

    const logSearch = () => {
        try {
            // DB Log
            apiService
                .post(`${API_LOG}`, payload)
                .then((response) => {
                    if (response.status === 200) {
                        console.info("User's search parameters logged.");
                    }
                })
                .catch((error) => {
                    console.error(error);
                });

            // Analytics
            const sp_filters = convertFiltersForAnalytics(filters, payload.SearchTerm);
            sp_click("N", "search", "Search", sp_filters, { _title: "Explore Search" });
            sp_track_params({ _title: "Explore Search", _class: "search" }, "");
        } catch (error) {
            console.error(error);
        }
    };

    return {
        fetchSearchResults,
        logSearch,
    };
};

export default useSolrSearch;
