import { useState, useEffect, useContext, useRef } from "react";
import { Masonry } from "react-masonry";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocalStorage } from "usehooks-ts";
import { AdvancedSearchContext } from "~/providers/AdvancedSearchProvider";
import useSolrSearch from "~/hooks/useSolrSearch";
import { SolrSearchResultImageItem } from "./types";
import ImageItem, { Image as ImageProps } from "./ImageItem";
import NoResult from "../NoResult";
import Loader from "~/ui/Loader";

const ImageResults = () => {
    const storage = import.meta.env.VITE_REGION + "_images";
    const [images, setImages] = useLocalStorage(storage, "");

    const [items, setItems] = useState<ImageProps[]>([]);
    const [loadedItems, setLoadedItems] = useState<ImageProps[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const totalItems = useRef(0);
    const refDiv = useRef<HTMLDivElement>(null);
    const [currentPage, setCurrentPage] = useState<number>(0);

    // State to track whether a search has been triggered
    const [searchTriggered, setSearchTriggered] = useState(false);

    // SolR Search implementation
    const SolRSearchType = `imagesearch-${import.meta.env.VITE_REGION}`;
    const { fetchSearchResults } = useSolrSearch(SolRSearchType, 16);
    const { modalOpen, searchTerm, triggerSearch, setTriggerSearch } = useContext(AdvancedSearchContext);

    const fetchData = async () => {
        try {
            fetchSearchResults(currentPage === 0).then((data: any) => {
                totalItems.current = data.total;
                const newItems: ImageProps[] = data.items.map(
                    (imgData: SolrSearchResultImageItem) =>
                        ({
                            imageID: imgData.ImageID,
                            imageGuid: imgData.ImageGuid,
                            imageFilePath: `/files/images/${imgData.ImageFilePath}`,
                            imageFilename: imgData.ImageOriginalFilename,
                            pdfFilePath: imgData.PdfFileName,
                            company: imgData.CompanyName,
                            year: imgData.Year,
                            documentGuid: imgData.DocumentGuid,
                            documentType: {
                                en: imgData.DocumentTypeUs,
                                fr: imgData.DocumentTypeFr,
                            },
                            metadata: {
                                en: imgData.MetadataUs,
                                fr: imgData.MetadataFr,
                            },
                        } as ImageProps)
                );

                setItems((prevItems) => [...prevItems, ...newItems]);
                loadImages(newItems);
            });
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const loadImage = async (item: ImageProps): Promise<ImageProps> => {
        return new Promise<ImageProps>((resolve) => {
            const img = new Image();
            img.onload = () => {
                resolve({ ...item, width: img.naturalWidth, height: img.naturalHeight });
            };
            img.onerror = () => {
                console.error("Error loading image: ", `${import.meta.env.VITE_API_HOST}${item.imageFilePath}`);
                resolve({ ...item });
            };
            img.src = `${import.meta.env.VITE_API_HOST}${item.imageFilePath}`;
        });
    };

    const loadImages = async (newItems: ImageProps[]) => {
        try {
            const loadedItems = await Promise.all(newItems.map(loadImage));
            setLoadedItems((prevItems) => [...prevItems, ...loadedItems]);
            setIsFetching(false);
            setIsLoading(false);
        } catch (error) {
            console.error("Error loading images:", error);
        }
    };

    const reset = () => {
        if (refDiv && refDiv.current) {
            refDiv.current.scrollTo(0, 0);
        }

        setIsLoading(true);
        setIsFetching(true);
        setItems([]);
        setLoadedItems([]);
        setCurrentPage(0);
    };

    const getNextData = () => {
        setIsFetching(true);
        setCurrentPage((prevPage) => prevPage + 1);
    };

    useEffect(() => {
        setSearchTriggered(true);
    }, [currentPage]);

    useEffect(() => {
        if (!modalOpen) {
            reset();
            setSearchTriggered(true);
        } else {
            setCurrentPage(0);
        }
    }, [modalOpen]);

    useEffect(() => {
        if (triggerSearch) {
            reset();
            setTriggerSearch(false);
            setSearchTriggered(true);
        }
    }, [triggerSearch]);

    useEffect(() => {
        reset();
        setTriggerSearch(false);
        setSearchTriggered(true);
    }, [searchTerm]);

    useEffect(() => {
        if (searchTriggered) {
            fetchData();
            setSearchTriggered(false);
        }
    }, [searchTriggered]);

    // useEffect(() => {
    //     DetailsStore.setState((state) => ({
    //       ...state,
    //       prevItem: items[state.index - 1],
    //       nextItem: items[state.index + 1],
    //     }));
    // }, [index]);

    const renderSkeleton = () => {
        return (
            <div className="grid grid-cols-4 gap-2 p-2 text-left overflow-auto">
                {[...Array(4)].map((_, parent) => (
                    <div className="flex flex-col gap-2" key={`image-result-col-key-${parent}`}>
                        {[...Array(3)].map((_, child) => (
                            <ImageLoaderItem key={`img-${parent}-item-${child}`} />
                        ))}
                    </div>
                ))}
            </div>
        );
    };

    // Initial loading
    if (isLoading) return renderSkeleton();

    return isFetching || totalItems.current > 0 ? (
        <div ref={refDiv} id="scrollableDivImgs" className="overflow-auto h-full p-2">
            <InfiniteScroll
                dataLength={items.length}
                next={getNextData}
                hasMore={items.length < totalItems.current && items.length === loadedItems.length}
                loader={""}
                scrollableTarget="scrollableDivImgs"
                style={{ overflow: "none" }}
            >
                <Masonry>
                    {loadedItems.map((item: ImageProps, index: number) => (
                        <div className="w-[25%] p-1" key={`key-image-result-${index}`}>
                            <ImageItem index={index} item={item} images={images} setImages={setImages} />
                        </div>
                    ))}
                </Masonry>
            </InfiniteScroll>
            {isFetching && <Loader className="max-h-[200px]" />}
        </div>
    ) : (
        <NoResult />
    );
};

const ImageLoaderItem = () => {
    const generateRandomNumber = (min: number, max: number) => {
        // Ensure min and max are numbers
        min = Number(min);
        max = Number(max);

        if (isNaN(min) || isNaN(max)) {
            throw new Error("Both min and max must be valid numbers");
        }

        // Generate a random number between min (inclusive) and max (exclusive)
        return Math.floor(Math.random() * (max - min)) + min;
    };

    return (
        <div className="w-full rounded-2xl bg-white overflow-hidden h-max">
            <div className="rounded-xl m-2 overflow-hidden">
                <div
                    className="w-full h-[300px] bg-gray-300 animate-pulse"
                    style={{ height: `${generateRandomNumber(150, 600)}px` }}
                ></div>
            </div>
            <div className="w-full p-6">
                <h3 className="text-xl text-foreground-dark h-5 bg-gray-300 mb-2 rounded-md"></h3>
                <p className="text-sm  h-4 bg-gray-200 w-1/3 rounded-md"></p>
                <p className="text-sm h-5  whitespace-nowrap text-ellipsis overflow-hidden"></p>
            </div>
        </div>
    );
};

export default ImageResults;
