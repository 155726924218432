import { useState, useEffect, useRef, useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocalStorage } from "usehooks-ts";
import useSolrSearch from "~/hooks/useSolrSearch";
import { AdvancedSearchContext } from "~/providers/AdvancedSearchProvider";
import NoResult from "../NoResult";
import TextItem, { Text } from "./TextItem";
import { SolrSearchResultTextItem } from "./types";

const TextResults = () => {
    const [texts, setTexts] = useLocalStorage("texts", "");
    const [items, setItems] = useState<Text[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const totalItems = useRef(0);
    const refDiv = useRef<HTMLDivElement>(null);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [searchTriggered, setSearchTriggered] = useState(false);

    // SolR Search implementation
    const { fetchSearchResults } = useSolrSearch("textsearch");

    const { searchTerm, modalOpen, triggerSearch, setTriggerSearch } = useContext(AdvancedSearchContext);

    const fetchData = async () => {
        if (!searchTerm) {
            setItems([]);
            setIsLoading(false);
            return;
        }

        try {
            fetchSearchResults(currentPage === 0).then((data: any) => {
                totalItems.current = data.total;
                const textItems = data.items;

                const newItems = data.items.map(
                    (txtData: SolrSearchResultTextItem) =>
                        ({
                            id: txtData.id,
                            pageID: txtData.PageID,
                            pageNum: txtData.PageNumber,
                            company: txtData.CompanyName,
                            year: txtData.Year,
                            documentType: txtData.DocumentType,
                            documentName: txtData.SourceDocumentName,
                            content: txtData.Content,
                            highlight: txtData.ContentHighlight,
                            thumbnail: `files/thumbnail/${txtData.ThumbnailPath}`,
                        } as Text)
                );

                if (textItems) {
                    setItems((prevItems) => [...prevItems, ...newItems]);
                }
                setIsLoading(false);
            });
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const getNextData = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const reset = () => {
        if (refDiv && refDiv.current) {
            refDiv.current.scrollTo(0, 0);
        }

        setIsLoading(true);
        setItems([]);
        setCurrentPage(0);
    };

    useEffect(() => {
        setSearchTriggered(true);
    }, [currentPage]);

    useEffect(() => {
        if (!modalOpen && !searchTerm) {
            reset();
            setSearchTriggered(true);
        } else {
            setCurrentPage(0);
        }
    }, [modalOpen]);

    useEffect(() => {
        reset();
        setTriggerSearch(false);
        setSearchTriggered(true);
    }, [searchTerm]);

    useEffect(() => {
        if (triggerSearch) {
            reset();
            setTriggerSearch(false);
            setSearchTriggered(true);
        }
    }, [triggerSearch]);

    useEffect(() => {
        if (searchTriggered) {
            fetchData();
            setSearchTriggered(false);
        }
    }, [searchTriggered]);

    const renderSkeleton = () => {
        return (
            <>
                {[...Array(6)].map((_, index) => {
                    return (
                        <div
                            className="relative rounded-lg shadow-sm py-2 px-3 flex bg-white items-start"
                            key={`text-item-skeleton-${index}`}
                        >
                            <div className="w-[248px] h-[320px] bg-gray-300 animate-pulse rounded-lg shrink-0"></div>
                            <div className="py-[75px] px-4 xl:px-6 text-sm text-foreground-light w-full">
                                <h3 className="text-[23px] w-72 rounded-md h-7 bg-gray-300 animate-pulse"></h3>
                                <p className="mb-2 w-48 rounded-md h-5 bg-gray-200 animate-pulse mt-4"></p>
                                <p className="mb-6 w-32 rounded-md h-5 bg-gray-200 animate-pulse"></p>
                                <p className="mb-2 w-full rounded-md h-5 bg-gray-200 animate-pulse"></p>
                                <p className="mb-2 w-1/2 rounded-md h-5 bg-gray-200 animate-pulse"></p>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    if (isLoading) {
        return <div className="grid grid-cols-2 gap-6 p-6 relative">{renderSkeleton()}</div>;
    }

    return totalItems.current > 0 ? (
        <div id="scrollableDivTexts" ref={refDiv} className="overflow-auto w-full min-w-[1240px] h-full">
            <InfiniteScroll
                dataLength={items.length}
                next={getNextData}
                hasMore={items.length < totalItems.current}
                loader={renderSkeleton()}
                scrollableTarget="scrollableDivTexts"
                className="grid grid-cols-2 w-full gap-4 p-4 xl:gap-6 xl:py-6 xl:px-12"
            >
                {items.map((text: Text, index: number) => {
                    return (
                        <TextItem
                            key={`key-text-index-${index}`}
                            text={text}
                            storedTexts={texts}
                            setTexts={setTexts}
                            markText={searchTerm}
                        />
                    );
                })}
            </InfiniteScroll>
        </div>
    ) : (
        <NoResult />
    );
};

export default TextResults;
